import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import type { SanityTextColor } from "../types";
import {
  MQ,
  interactionFeedback,
  typographyToCss,
} from "../../../styles/helpers";
import { colorsAsCssVariable as colors } from "../../../styles/theme/colors";
import spaces from "../../../styles/theme/spaces";
import text from "./ProductBanner.typography";
import { BannerSize } from "../../PriceDisplayPortals/price-display-portals-helper";
import { ImageWithFetchPrio } from "../../Common/Image/ImageWithFetchPrio";
import { BANNER_HEIGHTS } from "../Banner.styled";

type BannerSizeProps = {
  bannerSize: BannerSize;
};

export const ProductBrand = styled.span`
  ${typographyToCss(text.brand)}
  margin-bottom: ${spaces.micro};
`;

export const ProductName = styled.span`
  max-height: 70%;
  ${typographyToCss(text.name)}
`;

export const ProductDescription = styled.span`
  max-width: 141px;
  ${typographyToCss(text.description)}
  margin-top: ${spaces.smallRegular};
`;

export const CheapestPrice = styled.span`
  ${typographyToCss(text.cheapestPrice)}
`;

type ProductInfoWrapperProps = {
  textColor: SanityTextColor;
};

export const ProductInfoWrapper = styled.div<ProductInfoWrapperProps>(
  ({ textColor }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    z-index: 1;
    color: ${textColor};
  `
);

export const SmallBannerProductInfoWrapper = styled(ProductInfoWrapper)`
  position: absolute;
  right: 0;
  left: auto;
  padding: 46px 24px 0 0;
  padding: 46px var(--space-large) 0 0;
`;

export const Image = styled(ImageWithFetchPrio)<BannerSizeProps>(
  ({ bannerSize }) => css`
    position: absolute;
    right: 0;
    bottom: 0;
    height: 80%;
    align-self: flex-end;
    margin: 0 ${spaces.smallRegular} ${spaces.smallRegular} 0;

    ${bannerSize === BannerSize.XS &&
    `
      max-height: 85px;
      ${MQ("tablet")} {
        max-height: 200px;
      }
    `}

    ${bannerSize === BannerSize.SMALL &&
    `
      max-height: 128px;
      ${MQ("tablet")} {
        max-height: 266px;
      }
    `}

    ${bannerSize === BannerSize.MEDIUM &&
    `
      max-height: 198px;
      ${MQ("tablet")} {
        max-width: min(100%, 425px);
        max-height: 425px;
        margin: 0 ${spaces.large} ${spaces.large} 0;
      }
    `}
  `
);

type BackgroundWrapperProps = {
  backgroundColor?: string;
} & BannerSizeProps;

export const BackgroundWrapper = styled.div<BackgroundWrapperProps>(
  ({ backgroundColor, bannerSize }) => css`
    display: flex;
    flex-direction: column;
    padding: ${spaces.smallRegular};
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-color: ${backgroundColor ?? colors.xxlOrange};

    ${interactionFeedback(
      `&::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.04);
        animation: fadeIn 80ms;
        content: "";
      }
      
      .product-banner-image {
        transform: scale(102%);
        transition: transform 80ms ease-out; 
        transition-timing-function: ease-in;
        transform-origin: bottom right; 
      }`
    )}

    ${bannerSize === BannerSize.XS &&
    `
        min-height: ${BANNER_HEIGHTS.extra_small.mobile}
      `}

    ${bannerSize === BannerSize.MEDIUM &&
    `
        ${MQ("laptop")} {
            padding: ${spaces.large};
        }
      `}
  `
);

export const Icon = styled.img<BannerSizeProps>(
  ({ bannerSize }) => css`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    padding: ${spaces.smallRegular};

    ${MQ("laptop")} {
      width: 80px;
    }

    ${bannerSize === BannerSize.XS &&
    `
      width: 30px;
      ${MQ("laptop")} {
        width: 60px;
      }
    `}

    ${bannerSize === BannerSize.MEDIUM &&
    `
      ${MQ("laptop")} {
          padding: ${spaces.large};
      }
    `}
  `
);

type PriceWrapperProps = {
  textColor: SanityTextColor;
};

export const PriceWrapper = styled.div<PriceWrapperProps>(
  ({ textColor }) => css`
    display: flex;
    flex-direction: column;
    z-index: 1;
    color: ${textColor};
  `
);

export const RibbonWrapper = styled.div<BannerSizeProps>(
  ({ bannerSize }) => css`
    position: absolute;
    right: 0;
    margin-top: -${spaces.smallRegular};

    ${bannerSize === BannerSize.MEDIUM &&
    `
        ${MQ("laptop")} {
          margin-top: -${spaces.large};
        }
      `}
  `
);

export const Button = styled.button`
  margin-top: ${spaces.large};
`;
