import React from "react";
import type { GridBanner } from "@xxl/content-api";
import { GridBannerTypeEnum } from "@xxl/content-api";
import type { OptionalPriceDisplaysProps } from "../PriceDisplayPortals/types";
import type { SanityTextColor } from "../Banners/types";
import RegularBanner from "../Banners/RegularBanner/RegularBanner";
import { ComponentWrapper } from "./GridComponent.styled";
import ProductBanner from "../Banners/ProductBanner/ProductBanner";
import HighlightedProductBanner from "../Banners/HighlightedProductBanner/HighlightedProductBanner";
import { colorsAsHex } from "../../styles/theme/colors";
import { BannerSize } from "../PriceDisplayPortals/price-display-portals-helper";
import Banner from "../Banners/Banner";

type GridComponentProps = {
  banners: GridBanner[];
  productsPriceList?: OptionalPriceDisplaysProps;
};

export const resolveTextColor = (colorTitle?: string): SanityTextColor =>
  colorTitle === "XXL Black" ? "black" : "white";

const resolveBannerSize = (type?: GridBannerTypeEnum): BannerSize => {
  switch (type) {
    case GridBannerTypeEnum.xsProductBanner:
    case GridBannerTypeEnum.gridBanner:
      return BannerSize.XS;
    case GridBannerTypeEnum.smallProductBanner:
    case GridBannerTypeEnum.smallBanner:
    case GridBannerTypeEnum.highlightedSmallProductBanner:
      return BannerSize.SMALL;
    default:
      return BannerSize.MEDIUM;
  }
};

const renderProperBanner = ({
  banner,
  productsPriceList,
  positionInHomepage,
}: {
  banner: GridBanner;
  productsPriceList?: OptionalPriceDisplaysProps;
  positionInHomepage: number;
}): JSX.Element => {
  const priceData = productsPriceList?.[banner.product?.productCode ?? ""];
  const textColor = resolveTextColor(banner.textColorPicker?.title);
  const size = resolveBannerSize(banner._type);
  switch (banner._type) {
    case GridBannerTypeEnum.smallBanner:
    case GridBannerTypeEnum.mediumBanner:
    case GridBannerTypeEnum.gridBanner:
      return (
        <RegularBanner
          tagline={banner.typeText}
          title={banner.text}
          description={banner.description}
          taglineField={banner.tagline}
          titleField={banner.titleField}
          descriptionField={banner.descriptionField}
          size={size}
          url={banner.url}
          textColor={textColor}
          textPosition={banner.textPosition}
          hasImageOverlay={Boolean(banner.imageOverlay)}
          image={banner.image}
          countdownTimerSettings={banner.countdownTimerSettings}
          backgroundColor={banner.backgroundColor?.value}
          buttonText={banner.button}
          positionInHomepage={positionInHomepage}
        />
      );
    case GridBannerTypeEnum.xsProductBanner:
    case GridBannerTypeEnum.smallProductBanner:
    case GridBannerTypeEnum.mediumProductBanner:
      return (
        <ProductBanner
          size={size}
          textColor={textColor}
          product={banner.product}
          cheapestPrice={banner.additionalPriceLabel}
          productName={banner.productName}
          productBrand={banner.productBrand}
          productDescription={banner.productDescription}
          backgroundColor={banner.backgroundColor?.value}
          icon={banner.icon}
          priceData={priceData}
          countdownTimerSettings={banner.countdownTimerSettings}
          buttonLabel={banner.button}
          positionInHomepage={positionInHomepage}
        />
      );
    case GridBannerTypeEnum.highlightedSmallProductBanner:
    case GridBannerTypeEnum.highlightedMediumProductBanner:
      return (
        <HighlightedProductBanner
          size={size}
          headline={banner.headline}
          label={banner.typeText}
          subtitle={banner.subtitle}
          textColor={textColor}
          discountTextColor={resolveTextColor(banner.discountTextColor?.title)}
          discountBackgroundColor={
            banner.discountBackgroundColor?.value ?? colorsAsHex.xxlRed
          }
          priceTextColor={resolveTextColor(banner.priceTextColor?.title)}
          priceBackgroundColor={
            banner.priceBackgroundColor?.value ?? colorsAsHex.xxlOrange
          }
          product={banner.product}
          cheapestPrice={banner.additionalPriceLabel}
          productName={banner.productName}
          productDescription={banner.productDescription}
          backgroundColor={banner.backgroundColor?.value}
          icon={banner.icon}
          logo={banner.logo}
          priceData={priceData}
          countdownTimerSettings={banner.countdownTimerSettings}
          positionInHomepage={positionInHomepage}
        />
      );

    // TODO: It's TEMPORARY, it has to be changed when we have all banners rewritten
    default:
      return <></>;
  }
};

const GridComponent: React.FunctionComponent<GridComponentProps> = ({
  banners,
  productsPriceList,
}) => (
  <ComponentWrapper>
    {banners.map((item, index) => (
      <Banner
        key={index}
        size={resolveBannerSize(item._type)}
        url={item.url !== undefined ? item.url : item.product?.url}
      >
        {renderProperBanner({
          banner: item,
          productsPriceList,
          positionInHomepage: 0,
        })}
      </Banner>
    ))}
  </ComponentWrapper>
);

export default GridComponent;
