import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ, typographyToCss } from "../../../../styles/helpers";
import { colorsAsCssVariable as colors } from "../../../../styles/theme/colors";
import text from "./PriceInfoRibbon.typography";
import { ribbonRight } from "../../../../styled/mixins";
import type { RibbonCommonProps } from "../../ProductBanner/Ribbon/Ribbon.styled";
import { RibbonContentCommon } from "../../ProductBanner/Ribbon/Ribbon.styled";
import { BannerSize } from "../../../PriceDisplayPortals/price-display-portals-helper";

type RibbonProps = {
  isDiscountInfo: boolean;
  bannerSize: BannerSize;
} & RibbonCommonProps;

const mediumBanner = {
  discountHeight: "58px" as const,
  discountMobileHeight: "38px" as const,
  priceHeight: "84px" as const,
  priceMobileHeight: "48px" as const,
};

const smallBanner = {
  discountHeight: "40px" as const,
  discountMobileHeight: "34px" as const,
  priceHeight: "48px" as const,
  priceMobileHeight: "38px" as const,
};

const heights = {
  [BannerSize.MEDIUM]: mediumBanner,
  [BannerSize.SMALL]: smallBanner,
  [BannerSize.XS]: smallBanner, // made for consistency, currently no use case scenario since we don't have xs highlighted banners
};

export const RibbonWrapper = styled.span<RibbonProps>(
  ({ backgroundColor, isDiscountInfo, bannerSize }) => {
    const {
      discountMobileHeight,
      priceMobileHeight,
      discountHeight,
      priceHeight,
    } = heights[bannerSize];

    return css`
      display: flex;
      height: ${isDiscountInfo ? discountMobileHeight : priceMobileHeight};
      margin-right: auto;
      background-color: transparent;

      &::after {
        width: 0;
        height: 0;
        border-left: 1px solid ${backgroundColor ?? colors.xxlGreen};
        border-top: ${backgroundColor ?? colors.xxlGreen};
        border-top-style: solid;
        ${ribbonRight(
          isDiscountInfo ? discountMobileHeight : priceMobileHeight
        )};
        content: "";
      }

      ${MQ("laptop")} {
        height: ${isDiscountInfo ? discountHeight : priceHeight};

        &::after {
          ${ribbonRight(isDiscountInfo ? discountHeight : priceHeight)};
        }
      }
    `;
  }
);

export const RibbonContent = styled(RibbonContentCommon)<RibbonProps>(
  ({ isDiscountInfo, bannerSize }) => css`
    ${typographyToCss(
      isDiscountInfo
        ? text.discountContent[bannerSize]
        : text.priceContent[bannerSize]
    )};
  `
);
