import styled from "@emotion/styled/macro";
import {
  MQ,
  interactionFeedback,
  typographyToCss,
} from "../../../styles/helpers";
import spaces from "../../../styles/theme/spaces";
import text from "./HighlightedProductBanner.typography";
import { BackgroundWrapper as ProductBackgroundWrapper } from "../ProductBanner/ProductBanner.styled";
import { css } from "@mui/material/styles";
import { BannerSize } from "../../PriceDisplayPortals/price-display-portals-helper";
import { ImageWithFetchPrio } from "../../Common/Image/ImageWithFetchPrio";

type HeadlineProps = {
  bannerSize: BannerSize;
};

export const ProductName = styled("span")<HeadlineProps>(
  ({ bannerSize }) => css`
    max-width: 147px;
    ${typographyToCss(
      bannerSize === BannerSize.MEDIUM ? text.name : text.description
    )}
    margin: ${spaces.mini} 0 ${spaces.micro};
  `
);

export const ProductDescription = styled.span`
  max-width: 147px;
  ${typographyToCss(text.description)}
`;

export const Label = styled.span`
  ${typographyToCss(text.label)}
  margin-bottom: ${spaces.micro};
`;

export const Headline = styled("span")<HeadlineProps>(
  ({ bannerSize }) => css`
    ${typographyToCss(
      bannerSize === BannerSize.MEDIUM
        ? text.headline
        : text.smallBannerHeadline
    )}
    margin: 0 0 ${spaces.micro};
  `
);

export const Subtitle = styled.span`
  ${typographyToCss(text.subtitle)}
  margin-bottom: ${spaces.micro};
`;

export const BackgroundWrapper = styled(ProductBackgroundWrapper)(
  ({ bannerSize }) => css`
    ${interactionFeedback(
      `&::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.04);
      animation: fadeIn 80ms;
      content: "";
    }
    
    .highlighted-product-banner-image {
      transform: scale(102%);
      transition: transform 80ms ease-out; 
      transition-timing-function: ease-in;
      transform-origin: bottom right; 
    }`
    )}

    padding: ${bannerSize === BannerSize.MEDIUM
      ? spaces.smallRegular
      : spaces.mini};

    ${MQ("laptop")} {
      color: red;
      padding: ${bannerSize === BannerSize.MEDIUM
        ? spaces.large
        : spaces.smallRegular};
    }
  `
);

export const SmallBannerImage = styled(ImageWithFetchPrio)`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: ${spaces.smallRegular};
  margin: auto;
  height: 80%;
  align-self: flex-end;
  transform-origin: bottom right;
  transform: scale(102%);
  transition: transform 80ms ease-out;
  transition-timing-function: ease-in;
  ${MQ("laptop")} {
    left: 0;
  }
`;

export const Image = styled(ImageWithFetchPrio)`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 80%;
  align-self: flex-end;
  margin: 0 ${spaces.smallRegular} ${spaces.smallRegular} 0;
  max-height: 198px;

  ${MQ("tablet")} {
    max-width: min(100%, 425px);
    max-height: 425px;
  }

  ${MQ("laptop")} {
    margin: 0 ${spaces.large} ${spaces.large} 0;
  }
`;

export const Icon = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  padding: ${spaces.smallRegular};

  ${MQ("laptop")} {
    width: 80px;
    padding: ${spaces.large};
  }
`;

export const Logo = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  max-height: 25px;
  padding: ${spaces.smallRegular};

  ${MQ("laptop")} {
    max-height: 40px;
    padding: ${spaces.large};
  }
`;
