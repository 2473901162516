import * as React from "react";
import type { ColorTheme } from "../../../Product/product-helper";
import {
  getBackgroundColorFromColorTheme,
  getForegroundColorFromColorTheme,
} from "../../../Product/product-helper";
import { RibbonContent, RibbonWrapper } from "./PriceInfoRibbon.styled";
import { Label } from "../../ProductBanner/Ribbon/Ribbon.styled";
import type { BannerSize } from "../../../PriceDisplayPortals/price-display-portals-helper";

interface HighlightedPriceInfoRibbonProps {
  isDiscountInfo: boolean;
  label?: string;
  colorTheme?: ColorTheme;
  bannerSize: BannerSize;
}

const HighlightedPriceInfoRibbon = ({
  label,
  colorTheme,
  isDiscountInfo,
  bannerSize,
}: HighlightedPriceInfoRibbonProps) => {
  const foregroundColor = getForegroundColorFromColorTheme(colorTheme ?? {});
  const backgroundColor = getBackgroundColorFromColorTheme(colorTheme ?? {});

  if (label === undefined) {
    return null;
  }

  return (
    <RibbonWrapper
      backgroundColor={backgroundColor}
      isDiscountInfo={isDiscountInfo}
      bannerSize={bannerSize}
    >
      <RibbonContent
        foregroundColor={foregroundColor}
        backgroundColor={backgroundColor}
        isDiscountInfo={isDiscountInfo}
        bannerSize={bannerSize}
      >
        <Label>{label}</Label>
      </RibbonContent>
    </RibbonWrapper>
  );
};

export { HighlightedPriceInfoRibbon };
