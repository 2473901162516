import * as React from "react";
import { Label, RibbonContent, RibbonWrapper } from "./Ribbon.styled";
import type { ColorTheme } from "../../../Product/product-helper";
import {
  getBackgroundColorFromColorTheme,
  getForegroundColorFromColorTheme,
} from "../../../Product/product-helper";
import type { BannerSize } from "../../../PriceDisplayPortals/price-display-portals-helper";

interface RibbonProps {
  bannerSize: BannerSize;
  label?: string;
  colorTheme?: ColorTheme;
}

const Ribbon = ({ bannerSize, label, colorTheme }: RibbonProps) => {
  const foregroundColor = getForegroundColorFromColorTheme(colorTheme ?? {});
  const backgroundColor = getBackgroundColorFromColorTheme(colorTheme ?? {});

  if (label === undefined) {
    return null;
  }
  return (
    <RibbonWrapper bannerSize={bannerSize} backgroundColor={backgroundColor}>
      <RibbonContent
        bannerSize={bannerSize}
        foregroundColor={foregroundColor}
        backgroundColor={backgroundColor}
      >
        <Label>{label}</Label>
      </RibbonContent>
    </RibbonWrapper>
  );
};

export { Ribbon };
