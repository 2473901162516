import React from "react";
import type {
  Product,
  Image as IconData,
  CountdownTimerSettings,
} from "@xxl/content-api";
import type { PriceDisplayData } from "@xxl/frontend-api";
import type { SanityTextColor } from "../types";
import {
  BackgroundWrapper,
  ProductBrand,
  ProductDescription,
  ProductInfoWrapper,
  ProductName,
  Image,
  CheapestPrice,
  Icon,
  PriceWrapper,
  RibbonWrapper,
  Button,
} from "./ProductBanner.styled";
import {
  getFrontendPriceDisplayData,
  hasUndeductedRewardDiscount,
  isBundleSavingVariant,
} from "../../../utils/PriceDisplay/price-display";
import { useSessionSource } from "../../../contexts/Session";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import {
  SalesPrice,
  OtherPriceWrapper,
  OtherPrice,
  OtherPriceDisclaimer,
} from "../../PriceDisplayPortals/PriceDisplayPortals.styled";
import { BannerSize } from "../../PriceDisplayPortals/price-display-portals-helper";
import { formatPriceSplash } from "../../Product/product-helper";
import { Ribbon } from "./Ribbon/Ribbon";
import { CountdownTimer } from "../../CountdownTimer";
import { handleError } from "../../../utils/xxl-image";
import { useSharedData } from "../../../contexts/SharedData";
import { CountdownTimeContainer } from "../Banner.styled";
import { useRelinkValue } from "react-relink";
import type { ImageSettings } from "../Shared/BannerContent/BannerContent.helper";
import {
  ProductBannerImageSettings,
  componentIndicesOnHomepageWithHighPrio,
  getImageProp,
} from "../Shared/BannerContent/BannerContent.helper";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type ProductBannerProps = {
  size: BannerSize;
  textColor: SanityTextColor;
  backgroundColor?: string;
  productBrand?: string;
  productName?: string;
  productDescription?: string;
  product?: Product;
  cheapestPrice?: string;
  icon?: IconData;
  priceData?: PriceDisplayData;
  countdownTimerSettings?: CountdownTimerSettings | null;
  buttonLabel?: string;
  positionInHomepage: number;
};

const ProductBanner = ({
  size,
  productBrand,
  productName,
  productDescription,
  textColor,
  backgroundColor,
  product,
  cheapestPrice,
  icon,
  priceData: priceDisplay,
  countdownTimerSettings,
  buttonLabel,
  positionInHomepage,
}: ProductBannerProps) => {
  const { t } = useTranslations();
  const isLoggedIn = useRelinkValue(useSessionSource);
  const { siteUid } = useSharedData().data;

  const frontendPriceDisplay =
    priceDisplay !== undefined
      ? getFrontendPriceDisplayData({
          priceDisplay,
          t,
          isLoggedIn,
          siteUid,
        })
      : null;

  const isTextBlack = textColor === "black";
  const isSmallBanner = size !== BannerSize.MEDIUM;

  const fetchPriority = componentIndicesOnHomepageWithHighPrio.includes(
    positionInHomepage
  )
    ? "high"
    : "low";
  const isMobile = useXxlMediaQuery("MobileMediaQuery");

  const imageSettings: ImageSettings = {
    fetchPriority,
    isMobile,
    sizes: ProductBannerImageSettings[size].sizes,
    srcSetSizes: ProductBannerImageSettings[size].srcSetSizes,
  };

  const image =
    product !== undefined
      ? {
          url: product.imageUrl,
          alt: productName,
        }
      : undefined;

  const backgroundImage =
    image !== undefined ? getImageProp(imageSettings, image) : undefined;

  return (
    <BackgroundWrapper backgroundColor={backgroundColor} bannerSize={size}>
      {frontendPriceDisplay?.priceSplash !== undefined &&
        priceDisplay !== undefined && (
          <RibbonWrapper bannerSize={size}>
            <Ribbon
              colorTheme={priceDisplay.colourTheme}
              bannerSize={size}
              label={formatPriceSplash(
                frontendPriceDisplay.priceSplash,
                Boolean(isBundleSavingVariant(priceDisplay.type)),
                t
              )}
            />
          </RibbonWrapper>
        )}
      <ProductInfoWrapper textColor={textColor}>
        {productBrand !== undefined && (
          <ProductBrand>{productBrand}</ProductBrand>
        )}
        {productName !== undefined && <ProductName>{productName}</ProductName>}
        {productDescription !== undefined && (
          <ProductDescription>{productDescription}</ProductDescription>
        )}
        {countdownTimerSettings !== null &&
          countdownTimerSettings?.date !== undefined && (
            <CountdownTimeContainer>
              <CountdownTimer
                date={new Date(countdownTimerSettings.date)}
                isBlackText={countdownTimerSettings.isBlack}
                isOnlyHours={countdownTimerSettings.isOnlyHours}
                description={countdownTimerSettings.description}
              />
            </CountdownTimeContainer>
          )}
      </ProductInfoWrapper>
      {frontendPriceDisplay !== null && priceDisplay !== undefined && (
        <PriceWrapper textColor={textColor}>
          <SalesPrice
            bannerSize={size}
            hasUndeductedRewardDiscount={hasUndeductedRewardDiscount({
              invertedPrice: priceDisplay.invertedPrice,
              invertedPriceFormatted: priceDisplay.invertedPriceFormatted,
              variant: priceDisplay.type,
              isMultiPackProduct: false,
            })}
            isDiscountedMainPrice={frontendPriceDisplay.isDiscountedMainPrice}
          >
            {frontendPriceDisplay.salesPrice}
          </SalesPrice>
          {frontendPriceDisplay.otherPrice !== undefined && (
            <OtherPriceWrapper isSmall={isSmallBanner}>
              <OtherPrice
                hasUndeductedRewardDiscount={hasUndeductedRewardDiscount({
                  invertedPrice: priceDisplay.invertedPrice,
                  invertedPriceFormatted: priceDisplay.invertedPriceFormatted,
                  variant: priceDisplay.type,
                  isMultiPackProduct: false,
                })}
                isInvertedPrice={frontendPriceDisplay.isInvertedPrice}
              >
                {frontendPriceDisplay.otherPrice}
              </OtherPrice>
              &nbsp;
              <OtherPriceDisclaimer>
                {frontendPriceDisplay.otherPriceDisclaimer}
              </OtherPriceDisclaimer>
            </OtherPriceWrapper>
          )}
          {cheapestPrice && <CheapestPrice>{cheapestPrice}</CheapestPrice>}
        </PriceWrapper>
      )}
      {buttonLabel !== undefined && (
        <Button
          className={`button button--small button--${
            isTextBlack ? "primary" : "secondary"
          }`}
        >
          {buttonLabel}
        </Button>
      )}
      {backgroundImage !== undefined && (
        <Image
          bannerSize={size}
          className="product-banner-image"
          sizes={backgroundImage.sizes}
          src={backgroundImage.url}
          srcSet={backgroundImage.srcSet}
          alt={backgroundImage.alt}
          fetchPriority={backgroundImage.fetchPriority}
          onError={handleError}
        />
      )}
      {isNotNullOrUndefined(icon?.url) && (
        <Icon src={icon.url} alt={icon.alt} bannerSize={size} />
      )}
    </BackgroundWrapper>
  );
};

export default ProductBanner;
