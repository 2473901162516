import React from "react";
import type {
  Product,
  Image as IconData,
  CountdownTimerSettings,
} from "@xxl/content-api";
import type { ColourThemeData, PriceDisplayData } from "@xxl/frontend-api";
import type { SanityTextColor } from "../types";
import {
  ProductInfoWrapper,
  CheapestPrice,
  PriceWrapper,
  SmallBannerProductInfoWrapper,
} from "../ProductBanner/ProductBanner.styled";
import {
  getFrontendPriceDisplayData,
  hasUndeductedRewardDiscount,
  isBundleSavingVariant,
} from "../../../utils/PriceDisplay/price-display";
import { useSessionSource } from "../../../contexts/Session";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import {
  OtherPriceWrapper,
  OtherPrice,
  OtherPriceDisclaimer,
} from "../../PriceDisplayPortals/PriceDisplayPortals.styled";
import { formatPriceSplash } from "../../Product/product-helper";
import {
  BackgroundWrapper,
  Headline,
  Label,
  ProductDescription,
  ProductName,
  Subtitle,
  Image,
  Icon,
  Logo,
  SmallBannerImage,
} from "./HighlightedProductBanner.styled";
import { useMediaQuery } from "@mui/material";
import {
  mobileMediaQuery,
  mobileAndTabletMediaQuery,
} from "../../../utils/xxl-screen";
import { HighlightedPriceInfoRibbon } from "./PriceInfoRibbon/PriceInfoRibbon";
import { BannerSize } from "../../PriceDisplayPortals/price-display-portals-helper";
import { handleError } from "../../../utils/xxl-image";
import { useSharedData } from "../../../contexts/SharedData";
import { CountdownTimer } from "../../CountdownTimer";
import { CountdownTimeContainer } from "../Banner.styled";
import { useRelinkValue } from "react-relink";
import type { ImageSettings } from "../Shared/BannerContent/BannerContent.helper";
import {
  ProductBannerImageSettings,
  componentIndicesOnHomepageWithHighPrio,
  getImageProp,
} from "../Shared/BannerContent/BannerContent.helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type HighlightedProductBannerProps = {
  size: BannerSize;
  headline?: string;
  label?: string;
  subtitle?: string;
  textColor: SanityTextColor;
  discountTextColor: SanityTextColor;
  priceTextColor: SanityTextColor;
  backgroundColor?: string;
  discountBackgroundColor?: string;
  priceBackgroundColor?: string;
  productName?: string;
  productDescription?: string;
  product?: Product;
  cheapestPrice?: string;
  icon?: IconData;
  logo?: IconData;
  priceData?: PriceDisplayData;
  countdownTimerSettings?: CountdownTimerSettings | null;
  positionInHomepage: number;
};

const HighlightedProductBanner = ({
  productName,
  productDescription,
  headline,
  label,
  subtitle,
  textColor,
  priceTextColor,
  discountTextColor,
  backgroundColor,
  discountBackgroundColor,
  priceBackgroundColor,
  product,
  cheapestPrice,
  icon,
  priceData: priceDisplay,
  logo,
  countdownTimerSettings,
  size,
  positionInHomepage,
}: HighlightedProductBannerProps) => {
  const { t } = useTranslations();
  const isLoggedIn = useRelinkValue(useSessionSource);
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isMobileAndTablet = useMediaQuery(mobileAndTabletMediaQuery);

  const { siteUid } = useSharedData().data;

  const frontendPriceDisplay =
    priceDisplay !== undefined
      ? getFrontendPriceDisplayData({
          priceDisplay,
          t,
          isLoggedIn,
          siteUid,
        })
      : null;

  const priceColorTheme: ColourThemeData = {
    backgroundColour: priceBackgroundColor,
    foregroundColour: priceTextColor,
  };

  const discountColorTheme: ColourThemeData = {
    backgroundColour: discountBackgroundColor,
    foregroundColour: discountTextColor,
  };

  const BannerImage = size === BannerSize.SMALL ? SmallBannerImage : Image;

  const fetchPriority = componentIndicesOnHomepageWithHighPrio.includes(
    positionInHomepage
  )
    ? "high"
    : "low";

  const imageSettings: ImageSettings = {
    fetchPriority,
    isMobile,
    sizes: ProductBannerImageSettings[size].sizes,
    srcSetSizes: ProductBannerImageSettings[size].srcSetSizes,
  };

  const image =
    product !== undefined
      ? {
          url: product.imageUrl,
          alt: productName,
        }
      : undefined;

  const backgroundImage =
    image !== undefined ? getImageProp(imageSettings, image) : undefined;

  const ProductNameAndDesc = () => (
    <>
      {productName && (
        <ProductName bannerSize={size}>{productName}</ProductName>
      )}
      {productDescription && (
        <ProductDescription>{productDescription}</ProductDescription>
      )}
    </>
  );

  return (
    <BackgroundWrapper backgroundColor={backgroundColor} bannerSize={size}>
      <ProductInfoWrapper textColor={textColor}>
        {label && <Label>{label}</Label>}
        {headline && <Headline bannerSize={size}>{headline}</Headline>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {countdownTimerSettings !== null &&
          countdownTimerSettings?.date !== undefined && (
            <CountdownTimeContainer>
              <CountdownTimer
                date={new Date(countdownTimerSettings.date)}
                isBlackText={countdownTimerSettings.isBlack}
                isOnlyHours={countdownTimerSettings.isOnlyHours}
                description={countdownTimerSettings.description}
              />
            </CountdownTimeContainer>
          )}
        {!isMobile && size === BannerSize.MEDIUM && <ProductNameAndDesc />}
      </ProductInfoWrapper>
      {!isMobileAndTablet && size === BannerSize.SMALL && (
        <SmallBannerProductInfoWrapper textColor={textColor}>
          <ProductNameAndDesc />
        </SmallBannerProductInfoWrapper>
      )}
      {frontendPriceDisplay !== null && (
        <PriceWrapper textColor={textColor}>
          {frontendPriceDisplay.priceSplash !== undefined &&
            priceDisplay !== undefined && (
              <HighlightedPriceInfoRibbon
                bannerSize={size}
                isDiscountInfo={true}
                colorTheme={discountColorTheme}
                label={formatPriceSplash(
                  frontendPriceDisplay.priceSplash,
                  Boolean(isBundleSavingVariant(priceDisplay.type)),
                  t
                )}
              />
            )}
          <HighlightedPriceInfoRibbon
            bannerSize={size}
            isDiscountInfo={false}
            label={frontendPriceDisplay.salesPrice}
            colorTheme={priceColorTheme}
          />
          {priceDisplay !== undefined &&
            frontendPriceDisplay.otherPrice !== undefined && (
              <OtherPriceWrapper isSmall={size === BannerSize.SMALL}>
                <OtherPrice
                  hasUndeductedRewardDiscount={hasUndeductedRewardDiscount({
                    invertedPrice: priceDisplay.invertedPrice,
                    invertedPriceFormatted: priceDisplay.invertedPriceFormatted,
                    variant: priceDisplay.type,
                    isMultiPackProduct: false,
                  })}
                  isInvertedPrice={frontendPriceDisplay.isInvertedPrice}
                >
                  {frontendPriceDisplay.otherPrice}
                </OtherPrice>
                &nbsp;
                <OtherPriceDisclaimer>
                  {frontendPriceDisplay.otherPriceDisclaimer}
                </OtherPriceDisclaimer>
              </OtherPriceWrapper>
            )}
          <CheapestPrice>{cheapestPrice}</CheapestPrice>
        </PriceWrapper>
      )}
      {backgroundImage !== undefined && (
        <BannerImage
          className="highlighted-product-banner-image"
          sizes={backgroundImage.sizes}
          src={backgroundImage.url}
          srcSet={backgroundImage.srcSet}
          alt={backgroundImage.alt}
          fetchPriority={backgroundImage.fetchPriority}
          onError={handleError}
        />
      )}

      {isNotNullOrUndefined(icon?.url) && (
        <Icon src={icon.url} alt={icon.alt} />
      )}
      {isNotNullOrUndefined(logo?.url) && (
        <Logo src={logo.url} alt={logo.alt} />
      )}
    </BackgroundWrapper>
  );
};

export default HighlightedProductBanner;
